import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  private _previousUrl: string;
  private _currentUrl: string;
  private _routeHistory = [];
  private flatSubject = new BehaviorSubject<number>(0);
  public flas$ = this.flatSubject.asObservable();

  constructor(private router: Router) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._setURLs(event);
        // navigated portal if page refersh
        if (location.href.includes('/auth/', 0)) {

        }
        else if (event.id === 1 && event.url === event.urlAfterRedirects) {
          const url = new URL(document.URL);
          const dest_path = url.pathname;
          if (dest_path !== '/card_updated') {
            this.router.navigate(['/portal']); 
          }
        }
      });
  }

  private _setURLs(event: NavigationEnd): void {
    this._previousUrl = this._currentUrl;
    this._currentUrl = event.urlAfterRedirects;
    if (this.router.url === '/portal') {
      this._routeHistory.length = 0;
    }
    this._routeHistory.push(event.urlAfterRedirects);
  }
  set flat(flat: number) {
    if (flat > 2) {
      this.flatSubject.next(1);
    } else {
      this.flatSubject.next(flat);
    }
  }
  set History(history: string[]) {
    this._routeHistory = history;
  }
  get routeHistory(): string[] {
    return this._routeHistory;
  }
}
