<div class="card-updated">
  <div class="widget">
    <app-menu [back_button]="false" [title]="'Account'"></app-menu>
    <div *ngIf="completed" class="widget__contents">
      Thank you very much.<br />
      Your card has been successfully authorized.<br />
      We are currently updating the payment card in our system.<br />
      <br />
      A confirmation screen will appear shortly. <br />
      If the confirmation screen does not appear, we kindly ask you to contact
      our customer service via this <a href="https://www.sakuramobile.jp/contact-us" target="_blank" rel="noopener noreferrer">link</a>.
    </div>

    <div *ngIf="completed" class="widget__contents">
      <div class="widget__contents__title">
        <h1 class="title">Card updated</h1>
      </div>
      <div class="widget__contents__information">
        <p>Thank you for updating your payment card information.</p>
        <p>It may take a moment for the information to be updated.</p>
      </div>
      <div class="widget__contents__image">
        <img
          src="../../../../../assets/images/submitSakuraFlower.svg"
          alt="sakura submitSakuraFlower"
          class="image"
        />
      </div>

      <div class="widget__contents__backlink">
        <p class="back--link">
          Back to
          <span class="sakura--text" routerLink="/portal">Contracts</span>.
        </p>
      </div>
    </div>
  </div>
</div>
