import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Constants } from '../constants';
import { LoginResult } from '../types/auth';
import { TopUpData } from '../types/top-up-data';
import { Utils } from '../utils';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class TopUpService {
  private topUpDataObject = new BehaviorSubject<TopUpData>(null);
  public topUpData$ = this.topUpDataObject.asObservable();
  constructor(
    private http: HttpClient
  ) { }

  public set setTopUpData(data: TopUpData) {
    console.log(data);
    this.topUpDataObject.next(data);
  }
  checkOutTopUpData(token: string, contract_product_id: number, data_amount: number): Observable<any> {
    return this.http.post(Constants.API_TOP_UP_DATA, {
            contract_product_id: contract_product_id,
            // data_amount: 100, // test staging env
            data_amount: data_amount,
            card_information: {
              token: token
            }
    },
    { headers: this.authHeader });
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization:
        'Bearer ' + (current_user ? current_user.data.auth_token : ''),
    });
  }
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
}
