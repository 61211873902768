import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailErrorStateMatcher } from 'src/app/error_state_matchers';
import { V1UserAPIService } from 'src/app/services/v1api.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  // input data
  public userId: string;
  public password: string;
  public passwordConfirmation: string;

  // form & validation
  public emailFormControl: FormControl;
  public emailErrorMatcher: EmailErrorStateMatcher;
  public passwordFormControl: FormControl;
  public passwordConfirmationFormControl: FormControl;

  // control states
  public submitDisabled: boolean;
  public passwordNotMatch: boolean;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
  ) {
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.emailErrorMatcher = new EmailErrorStateMatcher();
    this.passwordFormControl = new FormControl('', [
      Validators.required
    ]);
    this.passwordConfirmationFormControl = new FormControl('', [
      Validators.required
    ]);
    this.userId = '';
    this.password = '';
    this.passwordConfirmation = '';
    this.submitDisabled = true;
    this.passwordNotMatch = false;
  }

  ngOnInit() {
    // START : ===== common implementation for shoud not login components =====
    if (this._api.isLoggingIn(true) === true) {
      console.log("SignUpComponent::ngOnInit : isLoggingIn = true -> navigate to /portal");
      this._router.navigate(['/portal']);
    }
    // END : ===== common implementation for shoud not login components =====
  }

  private enableSubmit() {
    this.submitDisabled = false;
    this.passwordNotMatch = false;
    if (this.emailFormControl.hasError('email')) {
      this.submitDisabled = true;
    }
    if (this.emailFormControl.hasError('required')) {
      this.submitDisabled = true;
    }
    if (this.password.length <= 0) {
      this.submitDisabled = true;
    }
    if (this.passwordConfirmation.length <= 0) {
      this.submitDisabled = true;
    }
    if (this.password.length > 0 &&
      this.passwordConfirmation.length > 0 &&
      this.passwordConfirmation !== this.password) {
      this.submitDisabled = true;
      this.passwordNotMatch = true;
    }
  }

  public onEmailChanged(event) {
    console.log('SignUpComponent::onEmailChanged');
    this.userId = this.emailFormControl.value;
    this.enableSubmit();
  }

  public onPasswordChanged(event) {
    console.log('SignUpComponent::onPasswordChanged');
    this.password = this.passwordFormControl.value;
    this.enableSubmit();
  }

  public onPasswordConfirmationChanged(event) {
    console.log('SignUpComponent::onPasswordConfirmationChanged');
    this.passwordConfirmation = this.passwordConfirmationFormControl.value;
    this.enableSubmit();
  }

  public onClickSubmitWidget(event) {
    this._api.signUp(this.userId, this.password, this.passwordConfirmation);
  }
}
