import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Constants } from 'src/app/constants';
import { Utils } from 'src/app/utils';
import { LoginResult } from 'src/app/types/auth';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class V1UserAPIService {
  private _loggingIn = new Subject<boolean>();
  public loggingIn$ = this._loggingIn.asObservable();

  public desiredUrl: string;

  public brideData: any;

  public set loggingIn(isLoggingIn: boolean) {
    this._loggingIn.next(isLoggingIn);
  }

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.initialize();
  }
  // START : ===== for authentication =====
  private getloginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }

  private setLoginUser(user: LoginResult): void {
    localStorage.setItem(Constants.LOGIN_USER, JSON.stringify(user));
  }

  public signUp(userId: string, password: string, password_confirmation): any {
    return (
      this._http
        .post(Constants.API_SIGNUP, {
          email: userId,
          password: password,
          password_confirmation: password_confirmation,
          fe: environment.feSystemId
        }).
        subscribe(
          response => {
            console.log('AuthService::signup - reply success');
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayMessage(response, 'Your sign up request has been accepted.'),
              'OK',
              Constants.defaultSnackBarConfig('info'));
            this._router.navigate(['/auth/login']);
          },
          error => {
            console.log('AuthService::signup - reply error');
            console.log(error);
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayErrorMessage(error, 'Sign up failed'),
              'OK',
              Constants.defaultSnackBarConfig('error'));
          }
        )
    );
  }

  public confirmationResend(userId: string): any {
    return (
      this._http
        .post(Constants.API_CONFIRMATION_RESEND, {
          email: userId,
          fe: environment.feSystemId
        })
        .subscribe(
          response => {
            console.log('AuthService::confirmationResend - reply success');
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayMessage(
                response,
                'The email for confirmation has been resent. Please check the email sent to complete the sign up.'
              ),
              'OK',
              Constants.defaultSnackBarConfig('info'));
            this._router.navigate(['/auth/login']);
          },
          error => {
            console.log('AuthService::confirmationResend - reply error');
            console.log(error);
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayErrorMessage(
                error,
                'The request for confirmation was not processed due to an error.'
              ),
              'OK',
              Constants.defaultSnackBarConfig('error'));
          }
        )
    );
  }

  public login(userId: string, password: string): any {
    return (
      this._http
        .post(Constants.API_LOGIN, {
          email: userId,
          password: password,
          fe: environment.feSystemId
        })
        .subscribe(
          response => {
            console.log('AuthService::Login - response : ' + JSON.stringify(response));
            // devise-jwt : authentication reply
            const authResult = response as LoginResult;
            if (Utils.isValue(authResult.data.auth_token)) {
              this._snackBar.dismiss();
              this._snackBar.open(
                Utils.getDisplayMessage(response, 'Logging in'),
                'OK',
                Constants.defaultSnackBarConfig('success'));
              this.loggingIn = true;
              this.setLoginUser(authResult);
              if (this.desiredUrl !== null && this.desiredUrl.length > 0) {
                const desiredUrl = this.desiredUrl;
                this.desiredUrl = null;
                this._router.navigate([desiredUrl]);
              }
              // send email and pw to native OS
              if (Utils.isAndroid()) {
                this.sendUserInfoToAndroid(password, authResult);
              }
              if (Utils.isIOS()) {
                this.sendUserInfoToIOS(password, authResult);
              }
            }
          },
          error => {
            console.log('AuthService::Login - error');
            console.log(error);
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayErrorMessage(error, 'Login failed'),
              'OK',
              Constants.defaultSnackBarConfig('error'));
          }
        )
    );
  }

  public logout(): void {
    this._http.post(Constants.API_LOGOUT, null, { headers: this.authHeader })
      .subscribe(
        response => {
          console.log('AuthService::logout - redirect to login');
          localStorage.removeItem(Constants.LOGIN_USER);
          this.loggingIn = this.isLoggingIn();
          this._snackBar.dismiss();
          this._snackBar.open(
            'Logout.',
            'OK',
            Constants.defaultSnackBarConfig('success'));
          this._router.navigate(['/auth/login']);
        },
        error => {
          console.log('AuthService::logout - (error)redirect to login',error);
          localStorage.removeItem(Constants.LOGIN_USER);
          this.loggingIn = this.isLoggingIn();
          this._snackBar.dismiss();
          this._snackBar.open(
            'Logout.',
            'OK',
            Constants.defaultSnackBarConfig('success'));
          this._router.navigate(['/auth/login']);
        });
  }

  public passwordReset(userId: string): any {
    return (
      this._http
        .post(Constants.API_RESET_PASSWORD, {
          email: userId,
          fe: environment.feSystemId
        })
        .subscribe(
          response => {
            console.log('AuthService::passwordReset - reply success');
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayMessage(response, 'An e-mail with the link to set your password has been sent. Please check your e-mail.'),
              'OK',
              Constants.defaultSnackBarConfig('info'));
            this._router.navigate(['/auth/login']);
          },
          error => {
            console.log('AuthService::passwordReset - reply error');
            console.log(error);
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayErrorMessage(error, 'Your password reset request has not been accepted.'),
              'OK',
              Constants.defaultSnackBarConfig('error'));
          }
        )
    );
  }

  public passwordUpdate(token: string, password: string, password_confirmation: string): any {
    console.log('V1APIService::passwordUpdate : token=' + token);
    return (
      this._http
        .put(Constants.API_UPDATE_PASSWORD, {
          reset_password_token: token,
          password: password,
          password_confirmation: password_confirmation,
          fe: environment.feSystemId
        }).
        subscribe(
          response => {
            console.log('AuthService::passwordUpdate - reply success');
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayMessage(response, 'Your password has been updated. Please login again.'),
              'OK',
              Constants.defaultSnackBarConfig('info'));
            this._router.navigate(['/auth/login']);
          },
          error => {
            console.log('AuthService::passwordUpdate - reply error');
            console.log(error);
            this._snackBar.dismiss();
            this._snackBar.open(
              Utils.getDisplayErrorMessage(error, 'Password update failed.'),
              'OK',
              Constants.defaultSnackBarConfig('error'));
          }
        )
    );
  }

  public get5GStatus(params: any): Observable<any> {
    return this.get(`${Constants.API_GET_5G_STATUS}/${params['item_id']}`, null);
  }

  public set5GStatus(params: any): Observable<any> {
    return this.post(`${Constants.API_SET_5G_STATUS}/${params['item_id']}`, {enable: params['enable']});
  }

  public initialize(): void {
    this.loggingIn = this.isLoggingIn();
  }

  public isLoggingIn(checkServer: boolean = false): boolean {
    const current_user = this.getloginUser();
    if (current_user === undefined || current_user === null) {
      return false;
    }
    if (checkServer) {
      this.checkLoggingIn().subscribe(
        response => {
          this.loggingIn = true;
        },
        error => {
          console.log('AuthService::isLoggingIn - reply error');
          console.log(error);
          localStorage.removeItem(Constants.LOGIN_USER);
          this._router.navigate(['/auth/login']);
          this.loggingIn = false;
        }
      );
    }
    return true;
  }
  private sendUserInfoToIOS(pw: string, userLogin: any) {
    if ((window as any).webkit) {
      (window as any).webkit.messageHandlers.doStuffMessageHandler.postMessage(`${userLogin.data.user.email}#%#${pw}`);
    }
  }
  private sendUserInfoToAndroid(pw: string, userLogin: any) {
    if ((window as any).AndroidShareHandler) {
      (window as any).AndroidShareHandler.share2(`${userLogin.data.user.email}#%#${pw}`);
    }
  }
  public checkLoggingIn(): Observable<any> {
    return this.post(Constants.API_IS_LOGGING_IN, null);
  }

  public has_role(role_name: string): Observable<any> {
    return this.post(Constants.API_HAS_ROLE, { role_name: role_name });
  }

  private get authHeader(): HttpHeaders {
    const current_user = this.getloginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (current_user ? current_user.data.auth_token : '')
    });
  }

  // END : ===== for authentication =====

  public get(url: string, params: any): Observable<any> {
    console.log('V1UserAPIService::get : url=' + url);
    console.log('V1UserAPIService::get : params=' + JSON.stringify(params));
    const fullUrl = url + Utils.createUrlParamString(params);
    return this._http.get<any>(fullUrl, {
      headers: this.authHeader
    });
  }

  public post(url: string, params: any): Observable<any> {
    console.log('V1UserAPIService::post : url=' + url);
    console.log('V1UserAPIService::post : params=' + JSON.stringify(params));
    return this._http.post(url, params, {
      headers: this.authHeader,
      withCredentials: true
    });
  }

  public put(url: string, params: any): Observable<any> {
    console.log('V1UserAPIService::put : url=' + url);
    console.log('V1UserAPIService::put : params=' + JSON.stringify(params));
    const current_user = this.getloginUser();
    return this._http.put(url, params, {
      headers: this.authHeader,
      withCredentials: true
    });
  }
}
