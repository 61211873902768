
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { EmailErrorStateMatcher } from 'src/app/error_state_matchers';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private switchBanner: number;
  // input data
  public userId: string;
  public password: string;

  // form & validation
  public emailFormControl: FormControl;
  public emailErrorMatcher: EmailErrorStateMatcher;

  // control states
  public submitDisabled: boolean;
  @ViewChild('href', { static: true}) hrefElement: ElementRef;
  @ViewChild('image', { static: true}) imageElement: ElementRef;
  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
  ) {
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.emailErrorMatcher = new EmailErrorStateMatcher();
    this.password = '';
    this.submitDisabled = true;
  }

  ngOnInit() {
    console.log('IOS', Utils.isIOS());
    console.log('Android', Utils.isAndroid());
    // Switch banner every 10 seconds.
    let i = 0;
    this.switchBanner = (window as any).setInterval( () => {
      i = i + 1;
      this.changeBanner(i, 'Sakura Fiber Internet');
      if (i === 2) {
        i = 0;
        this.changeBanner(i, 'get 3000 give 3000 Campaign');
      }
    }, 10000);
    // End switch banner
    // START : ===== common implementation for shoud not login components =====
    if (this._api.isLoggingIn(true) === true) {
      console.log("LoginComponent::ngOnInit : isLoggingIn = true -> navigate to /portal");
      this._router.navigate(['/portal']);
    }
    // END : ===== common implementation for shoud not login components =====

  }
  ngOnDestroy () {
    clearInterval(this.switchBanner);
  }

  public onEmailChanged(event) {
    // console.log(event.target.value);
    this.userId = this.emailFormControl.value.toLowerCase();
    this.enableSubmit();
    if (Utils.isAndroid() || Utils.isIOS()) {
      if ((window as any).webkit) {
        (window as any).webkit.messageHandlers.doStuffMessageHandler2.postMessage({param1: event.target.value});
      }
    }
  }

  public onPasswordChanged(event) {
    // console.log(event.target.value);
    this.enableSubmit();
    if (Utils.isAndroid() || Utils.isIOS()) {
      if ((window as any).webkit) {
        (window as any).webkit.messageHandlers.doStuffMessageHandler2.postMessage({param1: event.target.value});
        this.enableSubmit();
      }
    }
  }

  public onClickSubmitWidget(event) {
    this._api.desiredUrl = '/portal';
    this._api.login(this.userId, this.password);
  }

  public onClickPasswordReset(event_data: any): void {
    this._router.navigate(['/auth/password_reset']);
  }

  public onClickResendConfirmation(event_data: any): void {
    this._router.navigate(['/auth/confirmation-resend']);
  }
  private changeBanner(i: number, alt: string): void {
    this.imageElement.nativeElement.src = Constants.srcImageOfBanner[i];
    this.imageElement.nativeElement.alt = alt;
    this.hrefElement.nativeElement.href = Constants.hrefLinkOfBanner[i];
  }
  private enableSubmit() {
    this.submitDisabled = false;
    if (this.emailFormControl.hasError('email')) {
      this.submitDisabled = true;
      return;
    }
    if (this.emailFormControl.hasError('required')) {
      this.submitDisabled = true;
      return;
    }
    if (this.password.length <= 0) {
      this.submitDisabled = true;
      return;
    }
  }
}
