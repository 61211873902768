import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { Account } from '../types/account';
import { Constants } from '../constants';
import { LoginResult } from '../types/auth';
import { Utils } from '../utils';
import { catchError, map, tap, switchMap, exhaustMap, shareReplay, finalize } from 'rxjs/operators';
import { AccountPaymentInformation } from '../types/account-payment-information';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { DepositUser } from '../types/deposit-user';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';
export interface PayMethodChangable {
  message: {
    status: string,
    message: string,
  };
  data: {
    status: boolean
  };
}
export interface PaymentChangeResponse {
  status: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AccountInformationService {
  // storage user information
  private userSubject = new BehaviorSubject<Account>(null);
  public userInformation$ = this.userSubject.asObservable();
  // storage user payment information
  private userPaymentSubject = new BehaviorSubject<AccountPaymentInformation>(null);
  public userPaymentInformation$ = this.userPaymentSubject.asObservable();
  // storage payment method choosed
  private paymentMethodSubject = new BehaviorSubject<string>('');
  public paymentMethod$ = this.paymentMethodSubject.asObservable();
  // deposit of user
  private depositOfUserSubject = new BehaviorSubject<DepositUser>(null);
  public depositOfUser$ = this.depositOfUserSubject.asObservable();
  // Total amount price
  private amountPriceSubject = new BehaviorSubject<number>(0);
  public totalPrice$ = this.amountPriceSubject.asObservable();

  public set setTotalPrice(totalprice$: Observable<number>) {
    this.totalPrice$ = totalprice$;
  }

  public set setDepositOfUser(depositInfo: Observable<DepositUser>) {
    this.depositOfUser$ = depositInfo;
  }
  public set setPayMethod(method: string) {
    this.paymentMethodSubject.next(method);
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackbar: MatSnackBar,
    private _loadingService: LoadingService
  ) { }

  public AccountInformation(): Observable<Account> {
    const current_user = this.getLoginUser();
    return this.http.get<Account>(Constants.API_ACCOUNT_INFORMATION + `/${current_user.data.user.id}`,
      { headers: this.authHeader })
      .pipe(
        tap(res => this.userSubject.next(res)),
        catchError(this.handleError),
        shareReplay()
      );

  }
  public AccountPaymentsInformation(): Observable<AccountPaymentInformation> {
    const current_user = this.getLoginUser();
    return this.http.get<AccountPaymentInformation>(Constants.API_ACCOUNT_PAYMENTS_INFORMATION + `/${current_user.data.user.id}`,
      { headers: this.authHeader })
      .pipe(
        tap(res => {
          console.log('Account payment information', res);
          this.userPaymentSubject.next(res);
        }),
        catchError(this.handleError)
      );
  }

  public UpdateCard(memberId: string, holderName: string, token1: string, token2: string): any {
    const current_user = this.getLoginUser();
    return this.http.post(Constants.API_UPDATE_CARD_INFORMATION, {
      user_id: current_user.data.user.id,
      member_id: memberId,
      holder_name: holderName,
      token: token1,
      token2: token2
    }, { headers: this.authHeader })
      .pipe(finalize(() => this._loadingService.setSpinnerEvent = false))
      .subscribe(
        response => {
          console.log('Updated successfull::', response);
          const r = <any>response;
          location.replace(r.data.redirect_url);
        },
        error => {
          console.log('Updated Error:', error);
          this._snackbar.dismiss();
          this._snackbar.open(
            'Updated Failed',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        }
      );
  }

  public UpdateCardCompleted(renew_hash: string): Observable<any> {
    return this.http.post<any>(Constants.API_UPDATE_CARD_DONE, {
      renew_hash: renew_hash
    }, { headers: this.authHeader })
  }

  public CheckPayMethodChangable(): Observable<boolean> {
    const current_user = this.getLoginUser();
    return this.http.get<PayMethodChangable>(Constants.API_PAY_METHOD_CHANGABLE + `/${current_user.data.user.id}`,
      { headers: this.authHeader })
      .pipe(
        map(res => res.data.status),
        tap(res => console.log('Check payment method changable::', res)),
        catchError(this.handleError)
      );
  }
  public InfoDepositOfUser(): Observable<DepositUser> {
    const current_user = this.getLoginUser();
    return this.http.get<DepositUser>(Constants.API_USER_DEPOSITS + `/${current_user.data.user.id}`,
      { headers: this.authHeader }).pipe(
        catchError(this.handleError)
      );
  }
  public ChangePayMethodNotify(): void {
    const currentUser = this.getLoginUser();
    let payMethod: string;
    this.paymentMethod$.subscribe(val => payMethod = val);
    this.http.post(Constants.API_CHANGE_PAYMENT_METHOD_NOTIFY, {
      user_id: currentUser.data.user.id,
      pay_deposit_by: payMethod
    }, { headers: this.authHeader })
      .pipe(
        catchError(this.handleError)
      )
      .subscribe(res => {
        console.log('Change payment method successfull::', res);
        this.router.navigate(['/change-payment-submitted-request']);
      });
  }
  // -----------------------------------------------------------
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (current_user ? current_user.data.auth_token : '')
    });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      localStorage.removeItem(Constants.LOGIN_USER);
      if (this.router) {
        this.router.navigate(['/auth/login']);
      }
    } else {
      let msg = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        msg = error.error.message;
      } else {
        // server-side error
        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(msg);
    }
  }
  //-----------------------------------------------------------
  public paymentChangeTerm(): Observable<boolean> {
    const current_user = this.getLoginUser();
    const url = `${environment.apiRoot}/api/v1/payments/pay_method_changeable_term`;
    return this.http.get<PayMethodChangable>(url,
      { headers: this.authHeader })
      .pipe(
        map(res => res.data.status),
        tap(res => console.log('Check payment method changable::', res)),
        catchError(this.handleError)
      );
  }
}
