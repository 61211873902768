import { Injectable, Inject, Renderer2 } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Invoice } from '../types/invoice';
import { DOCUMENT } from '@angular/common';
import { Constants } from '../constants';
import { CreditCard } from '../types/creditcard-information';
import { LoginResult } from '../types/auth';
import { Utils } from '../utils';
import { take, catchError, tap, finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { UserDetailTabInfo } from '../components/pages/admin/users/user-detail/user-detail.component';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentInvoicesService {
  private _payinvoces_inAction: boolean;
  private invoiceSubject = new BehaviorSubject<Invoice>(null);
  public invoice$ = this.invoiceSubject.asObservable();
  // type card pay : registered or new card
  private payTypeSubject = new BehaviorSubject<boolean>(null);
  public payType$ = this.payTypeSubject.asObservable();
  constructor(
    private http: HttpClient,
    private _router: Router,
    private _snackbar: MatSnackBar,
    private _loadingService: LoadingService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._payinvoces_inAction = false;
  }
  public set setInvoice(invoice: Invoice) {
    console.log('Invoice is setted::', invoice);
    this.invoiceSubject.next(invoice);
  }

  public set setPayType(type: boolean) {
    this.payTypeSubject.next(type);
  }

  public createScriptTab(renderer2: Renderer2): void {
    const script = renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = Constants.GMO_PAYMENT_GATEWAY;
    script.text = '';
    renderer2.appendChild(this._document.body, script);
  }

  public creditCardInformation(): Observable<CreditCard> {
    const user = this.getLoginUser();
    return this.http
      .get<CreditCard>(
        Constants.API_CREDITCARD_INFORMATION + `/${user.data.user.id}`,
        { headers: this.authHeader }
      )
      .pipe(tap(res => console.log('Credit card information', res)), take(3));
  }

  public payInvoice_newCard(
    token: string,
    invoice_id: number,
    email: string,
    holder_name: string
  ): any {
    const user = this.getLoginUser();
    if (this._payinvoces_inAction) {
      console.log('Paying now');
      return null;
    }
    this._payinvoces_inAction = true;
    return this.http
      .post(
        Constants.API_PAY_BY_NEW_CARD,
        {
          user_id: user.data.user.id,
          invoice_id: invoice_id,
          member_id: email,
          holder_name: holder_name,
          token: token,
        },
        { headers: this.authHeader }
      )
      .pipe(
        finalize(() => this._loadingService.setSpinnerEvent = false)
      )
      .subscribe(
        (response) => {
          this._payinvoces_inAction = false;
          console.log('Payment successfull:', response);
          this._snackbar.dismiss();
          this._snackbar.open(
            'Payment Successfull',
            'OK',
            Constants.defaultSnackBarConfig('success')
          );
          this._router.navigate(['/payment-received']);
        },
        (error: HttpErrorResponse) => {
          this._payinvoces_inAction = false;
          console.log('Payment Error:', error);
          this._snackbar.dismiss();
          this._snackbar.open(
            'Payment Failed',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        }
      );
  }
  public payinvoces_RegisteredCard(
    invoiceID: number,
    member_id: string,
    cardSeq: string
  ): any {
    const user = this.getLoginUser();
    if (this._payinvoces_inAction) {
      console.log('Paying now');
      return null;
    }
    this._payinvoces_inAction = true;
    return this.http
      .post(
        Constants.API_PAY_BY_NEW_CARD,
        {
          user_id: user.data.user.id,
          invoice_id: invoiceID,
          member_id: member_id,
          card_sequence: cardSeq,
        },
        { headers: this.authHeader }
      )
      .pipe(
        finalize(() => this._loadingService.setSpinnerEvent = false)
      )
      .subscribe(
        (response) => {
          this._payinvoces_inAction = false;
          console.log('Payment successfull:', response);
          this._snackbar.dismiss();
          this._snackbar.open(
            'Payment Successfull',
            'OK',
            Constants.defaultSnackBarConfig('success')
          );
          this._router.navigate(['/payment-received']);
        },
        (error: HttpErrorResponse) => {
          this._payinvoces_inAction = false;
          console.log('Payment Error:', error);
          this._snackbar.dismiss();
          this._snackbar.open(
            'Payment Failed',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        }
      );
  }
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization:
        'Bearer ' + (current_user ? current_user.data.auth_token : ''),
    });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      localStorage.removeItem(Constants.LOGIN_USER);
      this._router.navigate(['/auth/login']);
    } else {
      let msg = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        msg = error.error.message;
      } else {
        // server-side error
        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(msg);
    }
  }
}
