import { Injectable } from '@angular/core';
import { ContractsService } from './contracts.service';
import { BehaviorSubject, Subject, throwError, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { LoginResult } from '../types/auth';
import { Constants } from '../constants';
import { Utils } from '../utils';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Cancellation } from '../types/cancellation';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { CheckMNP } from '../types/check_mnp';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {

  constructor(
    private route: Router,
    private http: HttpClient,
    private contractServices: ContractsService
  ) { }

  // boolean MNP request
  private mnpRequest = new BehaviorSubject<boolean>(null);
  public mnpRequest$ = this.mnpRequest.asObservable();
  // storage phone number
  private line_number = new BehaviorSubject<string>('');
  public line_number$ = this.line_number.asObservable();
  // store contract product id
  private contract_product_id = new BehaviorSubject<number>(null);
  public contract_product_id$ = this.contract_product_id.asObservable();
  // storage form cancellation
  private formSubject = new BehaviorSubject<any>({});
  public formCancellation$ = this.formSubject.asObservable();
   // storage form mnp cancellation
   private mnpFormSubject = new BehaviorSubject<any>({});
   public mnpForm$ = this.mnpFormSubject.asObservable();
    // storage form refund paypal information
    private paypalSubject = new BehaviorSubject<any>({});
    public refundPaypalForm$ = this.paypalSubject.asObservable();
    // store jp bank information
    private jpBankSubject = new BehaviorSubject<any>({});
    public jpBank$ = this.jpBankSubject.asObservable();

    // storage mnp after request
  private checkMNPSubject = new BehaviorSubject<boolean>(null);
  public checkMnp$ = this.checkMNPSubject.asObservable();
  // Set line number
  public set setLineNumber(line_number: string ) {
    this.line_number.next(line_number);
  }
  // set mnp-request
  public set setMnpRequest(mnpState: boolean ) {
    this.mnpRequest.next(mnpState);
  }
  // set contract product id
  public set setcontractProductId(productId: number ) {
    this.contract_product_id.next(productId);
  }
  // set form subject
  public set setReasonsForm(form: FormGroup) {
    console.log('set reason form data', form);
    
    this.formSubject.next(form);
  }
  public set setMnpForm(form: FormGroup) {
    this.mnpFormSubject.next(form);
  }
  public set setRefundPaypal(form: FormGroup) {
    this.paypalSubject.next(form);
  }
  public set setJPBank(form: FormGroup) {
    this.jpBankSubject.next(form);
  }
  // Check mnp request
  public checkMnpRequest(product_id: number) {
    return this.http.get<CheckMNP>(Constants.API_CHECK_MNP_REQUEST  + `/${product_id}`, {headers: this.authHeader}).pipe(
      map(res => res.data.status),
      tap(res => this.checkMNPSubject.next(res)),
      catchError(this.handleError)
    );
  }

  public ContractsCancellation(data: any) {
    return this.http.post<any>(Constants.API_CONTRACT_CANCELLATION, { 'cancellation_data': data},
      { headers: this.authHeader })
    .pipe(
      catchError(this.handleError));
  }

  public ContractsCancellation_By_Mnp(data: any) {
    return this.http.post<any>(Constants.API_MNP_REQUEST, { 'cancellation_mnp_data': data},
      { headers: this.authHeader })
    .pipe(
      catchError(this.handleError)
    );
  }
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (current_user ? current_user.data.auth_token : '')
    });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      localStorage.removeItem(Constants.LOGIN_USER);
      this.route.navigate(['/auth/login']);
    } else {
      let msg = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        msg = error.error.message;
      } else {
        // server-side error
        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(msg);
    }
  }
}
