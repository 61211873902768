import { Component, OnInit } from "@angular/core";
import { V1UserAPIService } from "src/app/services/v1api.service";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {

  constructor(
    private _userService: V1UserAPIService,
    private _router: Router,
  ) {}

  ngOnInit() {
    // START : ===== common implementation for login required components =====
    if (this._userService.isLoggingIn(true) === false) {
      this._router.navigate(["/auth/login"]);
    } else {
      console.log("HomeComponent::ngOnInit : isLoggingIn = true -> navigate to /portal");
      this._router.navigate(["/portal"]);
    }
    // END : ===== common implementation for login required components =====
  }
}
