import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { RouterHistoryService } from "src/app/services/router-history.service";
import { TopUpService } from "src/app/services/top-up.service";
import { TopUpData } from "src/app/types/top-up-data";

@Component({
  selector: "app-top-up-select",
  templateUrl: "./top-up-select.component.html",
  styleUrls: ["./top-up-select.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TopUpSelectComponent implements OnInit {
  currentGB = 1; // GB unit
  currentPrice = 500;
  subTotal: number;
  vat: number;
  total: number;
  checkTop = false;
  checkBottom = false;
  mat_content_top = "radio_button_unchecked";
  mat_content_bottom = "radio_button_unchecked";
  private TopupCandidates = [
    { id: 1, data: 1, price: 500, subtotal: 500, vat: 50, total: 550 },
    { id: 2, data: 2, price: 1000, subtotal: 1000, vat: 100, total: 1100 },
    { id: 3, data: 3, price: 1500, subtotal: 1500, vat: 150, total: 1650 },
    { id: 4, data: 4, price: 2000, subtotal: 2000, vat: 200, total: 2200 },
    { id: 5, data: 5, price: 2500, subtotal: 2500, vat: 250, total: 2750 },
    { id: 6, data: 10, price: 5000, subtotal: 5000, vat: 500, total: 5500 },
    { id: 7, data: 15, price: 7500, subtotal: 7500, vat: 750, total: 8250 },
    { id: 8, data: 20, price: 10000, subtotal: 10000, vat: 1000, total: 11000 },
  ];

  selectingTopup: TopUpData = this.TopupCandidates[0];

  constructor(
    private _router: Router,
    private _topUpService: TopUpService,
    readonly _routerHistoryService: RouterHistoryService
  ) 
  {
  }

  public get FirstTopupCandidate(): TopUpData {
    return <TopUpData>this.TopupCandidates[0];
  }

  public get LastTopupCandidate(): TopUpData {
    return <TopUpData>this.TopupCandidates[this.TopupCandidates.length - 1];
  }

  public prevCandidate(): void {
    if (this.selectingTopup.id <= this.FirstTopupCandidate.id) return;
    const prev_id = this.selectingTopup.id - 1;
    this.selectingTopup = this.TopupCandidates.find((item) => item.id === prev_id);
    if (this.selectTopUp) {
      this._topUpService.setTopUpData = this.selectingTopup;
    }
  }

  public nextCandidate(): void {
    if (this.selectingTopup.id >= this.LastTopupCandidate.id) return;
    const next_id = this.selectingTopup.id + 1;
    this.selectingTopup = this.TopupCandidates.find((item) => item.id === next_id);
    if (this.selectTopUp) {
      this._topUpService.setTopUpData = this.selectingTopup;
    }
  }

  ngOnInit() {
    this._topUpService.setTopUpData = this.TopupCandidates[0];
  }

  selectTopUp() {
    this._router.navigate(["top-up-checkout"]);
  }

  toggleCheckedTop() {
    this.checkTop = !this.checkTop;
    this.checkTop
      ? (this.mat_content_top = "radio_button_checked")
      : (this.mat_content_top = "radio_button_unchecked");
  }
  toggleCheckedBottom() {
    this.checkBottom = !this.checkBottom;
    this.checkBottom
      ? (this.mat_content_bottom = "radio_button_checked")
      : (this.mat_content_bottom = "radio_button_unchecked");
  }
  // private calcSubTotal(amountsData: number) {
  //   const ObjectData = this.PriceData.find((item) => item.id === amountsData);
  //   return ObjectData.value;
  // }
  // private calcVat(price: number) {
  //   return price * 0.1;
  // }
  // private calcAll() {
  //   this.subTotal = this.calcSubTotal(this.defaultDataMB);
  //   this.vat = this.calcVat(this.subTotal);
  //   this.total = this.subTotal + this.vat;
  //   const data = {
  //     data: this.defaultDataMB,
  //     price: this.defaultPrice,
  //     subtotal: this.subTotal,
  //     vat: this.vat,
  //     total: this.total,
  //   };
  //   this._topUpService.setTopUpData = data;
  // }
}
