import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Constants } from 'src/app/constants';
import { LoginResult } from 'src/app/types/auth';
import { ReplaySubject, Observable, forkJoin, throwError, BehaviorSubject, Subject, concat, combineLatest, of } from 'rxjs';
import { catchError, map, take, filter, tap, retry, flatMap, shareReplay } from 'rxjs/operators';
import { Utils } from 'src/app/utils';
import { UserProperties } from 'src/app/types/user';
import { Contracts } from 'src/app/types/contracts';
import { environment } from 'src/environments/environment';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { ContractProduct } from '../types/contract-product';
import { Invoice, Invoices } from '../types/invoice';
import { Contract } from '../types/contract';
import { Coupon } from '../types/coupon';
import { Products } from '../types/products';
import { CallHistory } from '../types/call-history';
import { Product } from '../types/product';
import { PacketLog } from '../types/packet-log';
@Injectable({
  providedIn: 'root'
})

export class ContractsService {
  private contract = new Contract();
  // termination date
  private terminationDateSubject = new BehaviorSubject<string>('');
  public termination_date$ = this.terminationDateSubject.asObservable();
  // final payment
  private finalPaymentSubject = new BehaviorSubject<string>('');
  public final_payment$ = this.finalPaymentSubject.asObservable();
  // return device method
  private returnDeviceSubject = new BehaviorSubject<string>('');
  public return_device$ = this.returnDeviceSubject.asObservable();
  // payment-method$
  private payMethodSubject = new BehaviorSubject<string>('');
  public pay_method$ = this.payMethodSubject.asObservable();
  // monthly_plan$
  private monthly_plan = new BehaviorSubject<string>('');
  public monthly_plan$ = this.monthly_plan.asObservable();
  // array-invoices$
  private invoices = new BehaviorSubject<Invoices>([]);
  public invoices$ = this.invoices.asObservable();
  // contract$
  private contractSub = new BehaviorSubject<Contract>(this.contract);
  public contract$ = this.contractSub.asObservable();

  private deviceIDSubject = new BehaviorSubject<string>('');
  public deviceID$ = this.deviceIDSubject.asObservable();
  // product$
  private productSubject = new BehaviorSubject<Product>(null);
  public product$ = this.productSubject.asObservable();
  public set setDeviceID(deviceID: string) {
    this.deviceIDSubject.next(deviceID);
  }
  // start-at
  private start_at_ProductSubject = new BehaviorSubject<string>('');
  public start_at_Product$ = this.start_at_ProductSubject.asObservable();
  // item$
  private itemSubject = new BehaviorSubject<string>('');
  public item$ = this.itemSubject.asObservable();
  // product_id$
  private productIdSubject = new BehaviorSubject<number>(null);
  public productId$ = this.productIdSubject.asObservable();
  // END  ========
  constructor(
    private _route: Router,
    private http: HttpClient,
    private userService: V1UserAPIService
  ) { }
  set setTerminationDate(date: string) {
    console.log('setting termination date subject', date);
    
    this.terminationDateSubject.next(date);
  }
  set setFinalPayment(payment_date: string) {
    this.finalPaymentSubject.next(payment_date);
  }
  set setReturnDevice(method: string) {
    this.returnDeviceSubject.next(method);
  }

  private device_id = new BehaviorSubject<string>('');
  public device_id$ = this.device_id.asObservable();
  public set set_deviceID(deviceID: string) {
    console.log('ContractService:: ===================>>>>> deviceID$::', deviceID);
    this.deviceIDSubject.next(deviceID);
  }
  public set set_MonthlyPlan(monthly: string) {
    console.log('ContractService:: monthly$::', monthly);
    this.monthly_plan.next(monthly);
  }
  // Get phone_number
  public set set_Pay_Method(pay_method: string) {
    console.log('ContractService ==========================>>>> pay_method$::', pay_method);
    this.payMethodSubject.next(pay_method);
  }
  // set:: set invoice list
  public set set_invoice_list(invoices: Invoices) {
    const reveserInvoices = invoices.reverse();
    console.log('Invoice list $::', reveserInvoices);
    this.invoices.next(invoices);
  }
  // set: set contarct $
  public set set_contract(contract: Contract) {
    console.log('Contract$ ::', contract);
    this.contractSub.next(contract);
  }
  // set product
  public set setProduct(product: Product) {
    console.log('Set Product$:::', product);
    this.productSubject.next(product);
  }
  // set start_at of product
  public set set_start_at_Product(start_at: string) {
    console.log('======>>>> start_at is setted', start_at);
    
    this.start_at_ProductSubject.next(start_at);
  }
  public set set_Item(item: string) {
    console.log('ContractService ==========================>>>> item$::', item);
    this.itemSubject.next(item);
  }
  // END SET METHOD========
  public check_Product_Type(string: string): string {
    console.log('check type product =====>', string);
    
    if (string !== null && string !== undefined) {
      const stringLowerCase = string.toLocaleLowerCase();
      const items = ['home wifi', 'wifi', 'voice', 'data', 'fiber'];
      for (const item of items) {
        if (stringLowerCase.includes(item) === true) {
          console.log(`the item is ${item} (${stringLowerCase})`);
          return item.toLocaleLowerCase();
        }
      }
    } else {
      return '';
    }
  }
  // refesh payment history after pay
  public refeshInvoices(contract_id: number): void {
    const user = this.getLoginUser();
    this.http.post<any>(Constants.API_LIST_INVOICE,
      {
        'filter': [
          {
            'field': 'user_id',
            'type': 'number',
            'compare': 'eq',
            'value': user.data.user.id
          }
        ]
      }, { headers: this.authHeader })
      .pipe(
        map(invoices => {
          const { message: message, data: dataInvoices } = invoices;
          return dataInvoices; // Invoice []
        }),
        catchError(this.handleError)
      ).subscribe(invoices => {
        if (invoices.length !== 0) {
          const invoicesContract = invoices.filter(invoice => invoice.contract_id === contract_id);
          console.log(invoicesContract);
        this.invoices.next(invoicesContract);
        } else {
          this.invoices.next([]);
        }
      });
  }
  // get invoice follow contract id
  public findInvoice(invoices, contract) {
    return invoices.filter(invoice => {
      return invoice.contract_id === contract.id;
    });
    // return invoices.find(invoice => invoice.contract_id === contract.id);
  }
  // TEST: find invoices array
  public findArr_Invocies(invoices, contract) {
    if (invoices.filter === undefined) {
      return [];  // 20200318 : if there is no invoice, invoices argument becomes undefined.
    }
    return invoices.filter(invoice => invoice.contract_id === contract.id);
  }

  public checkPayStatus_Contract(contract, invoices) {
    const invoice = this.findInvoice(invoices, contract);
    if (invoice === undefined) {
      return false;
    } else {
      return (invoice.pay_status === 1) ? true : false;
    }
  }

  /**  get api list contract and invoices **/
  public listContractsInvoices(): Observable<any> {
    const user = this.getLoginUser();
    const listContracts = this.http.post<Contracts>(Constants.API_LIST_CONTRACTS,
      {
        'filter': [
          {
            'field': 'user_id',
            'type': 'number',
            'compare': 'eq',
            'value': user.data.user.id
          },
          {
            'field': 'key',
            'type': 'str_array',
            'compare': 'in',
            'value': [
                'SUBSCRIPTION PLAN',
                'PAY METHOD'
            ]
        }
        ]
      },
      { headers: this.authHeader })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
    const listInvoices = this.http.post<Invoices>(Constants.API_LIST_INVOICE,
      {
        'pagination': {
          'page': 1,
          'limit': 1000
        },
        'filter': [
          {
            'field': 'user_id',
            'type': 'number',
            'compare': 'eq',
            'value': user.data.user.id
          }
        ]
      }, { headers: this.authHeader }).pipe(
        retry(3),
        catchError(this.handleError)
      );
    return forkJoin(listContracts, listInvoices).pipe(
      shareReplay()
    );
  }
  /** Get phone number follow contract_id **/
public getPhone_Number(contract_id: number): Observable<any> {
  return this.http.get(Constants.API_GET_PHONE_NUMBER + `/${contract_id}`, {headers: this.authHeader})
  .pipe(tap(res => console.log('Api get phone number follow contract_id::', res)));
}
  /*** get contract details ***/
  public getListProduct(contract_id: number): Observable<Products> {
    return this.http.post<Products>(Constants.API_LIST_PRODUCT + `/${contract_id}`,
      {
        'filter': [
          {
            'field': 'key',
            'type': 'string',
            'compare': 'like',
            'value': 'DEVICE CODE 1'
          },
          // --- following filter had been failed ----
          // {
          //   'field': 'start_at',
          //   'type': 'date',
          //   'compare': 'not_eq',
          //   'value': 'null'
          // }
        ]
      },
      { headers: this.authHeader })
      .pipe(
        retry(3),
        catchError(this.handleError));
  }
  /*** Get contract product ****/
  public ContractProduct (contract_product_id: number): Observable<ContractProduct> {
    return this.http.post<ContractProduct>(Constants.API_CONTRACT_PRODUCT + `/${contract_product_id}`,
    {
      'filter': [
        {
          'field': 'key',
          'type': 'str_array',
          'compare': 'in',
          'value': ['KPD LINE NUMBER']
        }
      ]
    }, { headers: this.authHeader })
    .pipe(
      tap(res => console.log('Contract Product Data::', res)),
      retry(3),
      catchError(this.handleError)
    );
  }
  /*** Get user properties and contract product ****/
  public ContractProduct_UserPropertie(contract_product_id: number): Observable<any> {
    console.log('contract product id::::', contract_product_id);
    this.productIdSubject.next(contract_product_id);
    const user = this.getLoginUser();
    const user_properties$ = this.http.get<UserProperties>(Constants.API_USER_PROPERTIES + `/${user.data.user.id}`,
      { headers: this.authHeader });
    const contract_product$ = this.http.post<ContractProduct>(Constants.API_CONTRACT_PRODUCT + `/${contract_product_id}`,
      {
        'filter': [
          {
            'field': 'key',
            'type': 'str_array',
            'compare': 'in',
            'value': ['DEVICE CODE 1', 'KPD LINE NUMBER']
          }
        ]
      }, { headers: this.authHeader });
    return forkJoin([user_properties$, contract_product$])
      .pipe(
        retry(2),
        tap(res => console.log('contract products and user properties: ', res)),
        map(res => {
          const [a, b] = res;
          const { message: messUser, data: dataUser } = a;
          const { message: messProduct, data: dataProduct } = b;
          return [dataUser, dataProduct];
        }),
        catchError(this.handleError)
      );
  }
  public PacketLog_CallHistory(contract_product_id: number): Observable<[Coupon, PacketLog]> {
    const coupons$ = this.http.get<Coupon>(Constants.API_GET_VALID_COUPONS + `/${contract_product_id}`, {headers: this.authHeader});
    const PacketLog$ = this.http.post<PacketLog>(Constants.API_GET_PACKET_LOG + `/${contract_product_id}`,  { fe: environment.feSystemId },
    {headers: this.authHeader});
    return forkJoin([coupons$, PacketLog$]).pipe(
      retry(3),
      tap(res => console.log('Packetlog and Coupon form service::', res)),
      catchError(this.handleError)
    );
  }
  
  public CallHistory(contract_product_id: number, yearMonth: Array<string>): Observable<any> {
    return this.http.post<CallHistory>(Constants.API_GET_CALL_LOG + `/${contract_product_id}`, {'month': yearMonth},
    {headers: this.authHeader}).pipe(
      tap(res => console.log('callhistory response from Services::', res)),
      map( res => {
        const {message: mess_call, data: callHitory} = res;
        return callHitory;
      }),
      catchError(this.handleError)
    );
  }
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (current_user ? current_user.data.auth_token : '')
    });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 403) {
      localStorage.removeItem(Constants.LOGIN_USER);
      if (this._route) {
        this._route.navigate(['/auth/login']);
      }
    } else {
      let msg = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        msg = error.error.message;
      } else {
        // server-side error
        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(msg);
    }
  }



}
